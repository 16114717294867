.traversable-menu {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu__panel {

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0;
  width: 100%;
  transition: transform 350ms ease-in-out; /* If you change this, mke sure to also change the panel_slide_animation_duration setting when calling TraversableMenu */
  transform: translateX(100%);
  overflow-x: hidden;
  overflow-y: auto;

}

.menu__panel--depth-0,
.menu__panel--active-trail {
  transform: translateX(0);
}

.menu__panel.-show-immediate {
  transition-duration: 0ms;
}

.-show-immediate {
  transition-duration: 0ms !important;
}
