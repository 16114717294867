.menu {

  &--level-0 {

    .menu{

      &__items {
        align-items: center;
        text-align: center;
      }

      &__item {
        display: flex;
      }

      &__link {
        padding: rem(10) rem(10) 0 rem(10);
        position: relative;
        z-index: 2;
      }
    }
  }

  &--level-1 {
    position: relative;
    background-color: $white;
    width: 100%;
    z-index: 1;
    transition-property: height,opacity;
    transition-duration: $slow-duration;
    transition-timing-function: $cubic-ease;
    //opacity: 0;
    overflow: hidden;
    height: auto;
    pointer-events: none;

    @include bp(lg) {
      left: 0;
      top: 0;
      position: absolute;
    }

    .open & {
      opacity: 1;
      pointer-events: all;
    }

    .menu {
      align-items: center;
      display: flex;

      &__boundary {
        height: auto;
        display: flex;
        width: 1440px;
        padding: 0 rem(20);
        margin: 0 auto;
        align-items: center;
        overflow-y: hidden;
        opacity: 0;
        transition-property: height,opacity;
        transition-duration: .35s;
        transition-timing-function: $cubic-ease;

        @include bp(lg) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        .open & {
          opacity: 1;
        }
      }

      &__items {
        width: 100%;
        max-width: rem(1000);
        min-height: rem(260);
        margin: 0 auto;
        padding: rem(12) 0 rem(12) rem(22);
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        @include bp(lg) {
          padding: rem(170) rem(20) rem(28) rem(20);
        }
      }

      &__link {
        padding-left: 0;
        font-size: rem(14);
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
      }

      &__description {
        font-size: rem(12);
        line-height: rem(20);
        margin-top: rem(6);
        max-width: rem(200);
        font-family: $sans-serif-body-font;
      }

      &__item {
        display: block;
        flex-basis: 33.33%;
        text-align: left;
        margin-bottom: rem(32);
        padding-left: rem(22);
        position: relative;

        &::before {
          content: " ";
          top: 0;
          left: 0;
          position: absolute;
          width: 2px;
          height: 100%;
          background-color: $green;
          opacity: 0;
          transition-property: opacity;
          transition-duration: .15s;
          transition-timing-function: $cubic-ease;

          .header--desktop-menu & {
            transition-delay: 0.1s;
            opacity: 1;
          }
        }
      }
    }
  }
}
