.mainHeader {
  .menu-toggle {
    position: relative;
    align-content: center;

    @media only screen and (min-width: $medium2) {
      left: 0;
    }

    &__bar {
      display: block;
      background: $black;
      width: rem(30);
      height: 0.1875rem;
      margin: 0.84375rem 0;
      top: 12px;
      transition: background 0s .2s;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: rem(30);
        height: 0.1875rem;
        background: $black;
      }

      &::before {
        top: calc(0.84375rem - 10px);
        transition: top .2s ease .2s, transform .2s ease 0s;
      }

      &::after {
        bottom: calc(0.84375rem - 10px);
        transition: bottom .2s ease .2s, transform .2s ease 0s;
      }
    }

    &.is-active {
      span {
        background: none;

        &::before {
          top: 0.84375rem;
          transform: rotate(45deg);
          transition: top .2s ease 0s, transform .2s ease .2s;
        }

        &::after {
          bottom: 0.84375rem;
          transform: rotate(-45deg);
          transition: bottom .2s ease 0s, transform .2s ease .2s;
        }
      }
    }
  }

  .menu__panel {
    //@include standard-shadow;

    opacity: 0;
    transition: transform 350ms ease, opacity 350ms ease;
    transform: translateX(20px);
    pointer-events: none;
  }

  .menu__panel--active {
    transform: translateX(0);
    opacity: 1;
    z-index: 10;
    pointer-events: auto;
  }

  .menu__panel--active-trail {
    transform: translateX(0);
    z-index: 1;
  }

  .menu__panel--child-open {
    opacity: 1;
    overflow: hidden;
  }

  .header__menu--main__stage {
    position: relative;
    height: 100%;
  }

  .header__menu {
    position: fixed;
    right: 0;
    bottom: 0;
    top: rem(64);
    margin: 0;
    width: 100%;
    background: $white;
    transition: top .3s ease, transform .3s ease, opacity .3s ease;
    opacity: 0;
    transform: translate(20px, 0);
    pointer-events: none;
    z-index: 200;

    li {
      list-style: none;
    }

    .menu__panel--active {
      pointer-events: none;
    }

    .header--mobile-menu & {
      opacity: 1;
      transform: translate(0, 0);
      pointer-events: auto;

      @include bp(lg) {
        display: none;
      }

      .menu__panel--active {
        pointer-events: auto;
      }
    }

    nav {
      height: 100% !important; // override height set by module
    }
  }

  .menu__panel {
    background: $white;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100% !important; // override height set by module

    .menu-container {
      padding: rem(27) rem(33) rem(24) rem(33);
      margin: 0;
      width: 100%;
    }

    &__primary {
      &.menu-container {
        .back {
          > a {
            color: $black;
          }
        }
      }
    }
  }


  .menu-item {
    &:first-child {
      border-top: none;
    }

    &__link {
      border-bottom: 1px solid $light-grey-blue;
    }

    &__level0 {

      .link {
        display: flex;
        align-items: center;

        .menu-item {
          font-family: $sans-serif-body-font;
          font-size: rem(18);
          line-height: 1.61;
          flex: 1;
          color: $black;
          text-decoration: none;
          display: block;
          padding: rem(12) rem(10) rem(10) 0;

          &__explore {
            padding: rem(7) rem(9);
          }

          &:focus {
            outline: none;
          }
        }
      }

      // Styling for top-level links only
      >.link {
        >.menu-item {
          font-family: $sans-serif-font;
          font-weight: $font-weight-heavy;
          text-transform: uppercase;
        }
      }
    }
  }

  .menu-item__back {
    .back,
    .back-to-top {

      a {
        font-size: rem(14);
        text-transform: uppercase;
        line-height: 1;
        flex: 1;
        text-decoration: none;
        display: block;
        padding: 13px 0 11px;
        color: $black;

        &::before {
          margin: 0 10px 0 0;
          font-size: rem(16);
          vertical-align: middle;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .back-to-top {

      a::before {
        //content: $fa-var-chevron-up;
        position: relative;
        top: -3px;
      }
    }

    .back {
      @include text-link-arrow-left;

      display: flex;
      align-items: center;

      &::before {
        width: rem(10);
        height: rem(16);
      }
    }

    &.menu-item__level1 {
      .back-to-top {
        border-bottom: none;
      }
    }
  }

  .menu__panel__title {
    &__link {
      border-bottom: 1px solid $black;
      font-size: rem(18);
      font-family: $sans-serif-font;
      line-height: 1;
      font-weight: 700;
      flex: 1;
      text-transform: uppercase;
      display: block;
      padding: 13px 0 10px 0;
      color: $black;
    }
  }

  .menu__secondary {
    display: flex;
    position: relative;
    padding: 0 rem(33);
    width: 100%;

    &-list {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      &:first-child {
        margin: 0;
      }
    }

    &-item {
      flex-basis: 50%;
      padding-right: rem(16);

      a {
        display: block;
        padding: rem(14) 0 rem(10) 0;
        text-transform: uppercase;
        font-weight: $font-weight-book;
        font-size: rem(14);
        color: $black;
        text-decoration: none;
      }
    }
  }
}

// fade out content
.header {

    &__overlay {
      content: '';
      position: fixed;
      z-index: 100;
      left: 0;
      top: rem(109);
      right: 0;
      height: 100%;
      background: black;
      transition: top .2s ease, opacity .2s ease;
      opacity: 0;
      pointer-events: none;

      @media only screen and (min-width: $medium2) {
        top: rem(130);
        display: none;
      }

      @media only screen and (min-width: $large) {
        top: rem(130);
      }

      .header--compact & {
        @media only screen and (min-width: $medium2) {
          // top: $header-compact-height;
        }
      }
    }
}

.header--mobile-menu {

    .header__overlay {
      opacity: 0.7;
      pointer-events: auto;
    }

    &.header--compact {

      &::before {
        //top: $header-compact-height;
      }
    }
}
