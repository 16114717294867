@import '../partials';
@import 'Partials/MenuPrimary/menu-primary';
@import 'Partials/TraversableMenu/traversable-menu';
@import 'node_modules/traversable_menu/traversable_menu';

.mainHeader {
  position: absolute;
  width: 100%;
  z-index: 4;
}

.header--mobile-menu {
  position: fixed;
}

.header {
  position: relative;

  &__logo {
    position: relative;
    z-index: 2;
    margin: 0 rem(65) 0 0;
    font-size: 0;

    .header--mobile-menu & {
      display: none;
    }

    a {
      display: inline-block;
      width: rem(66);
      height: rem(32);

      @include bp(lg) {
        width: rem(90);
        height: rem(44);
      }
    }
  }

  &__top {
    z-index: 300; // position above mobile menu
    position: relative;

    @include bp(lg) {
      z-index: 2; // position below desktop menu
    }

    &__boundary {
      display: flex;
      justify-content: flex-end;
      width: 100vw;
      transition: opacity $fast-duration;

      .header--desktop-menu & {
        opacity: 0;
      }
    }
  }

  &__bottom {
    width: auto;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 rem(15);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 400;

    @include bp(lg) {
      padding: 0 rem(30);
      width: 96%;
      position: initial;
      z-index: 300;
    }

    &__boundary {
      display: flex;
      align-items: center;
      height: rem(64);

      @include bp(lg) {
        align-items: flex-end;
        height: rem(47);
      }
    }
  }

  &__boundary {
    position: relative;
  }

  &__menu-toggle {
    cursor: pointer;
    background-color: darken($green, 8%);
    display: flex;
    flex-direction: column;
    width: rem(64);
    height: rem(64);
    justify-content: center;
    align-items: center;

    @include bp(lg) {
      display: none;
    }

    .menu-toggle-icon {
      width: rem(38);
      height: rem(22);
      margin-bottom: 3px;
      display: flex;

      span {
        display: block;
        width: rem(38);
        height: 3px;
        background-color: $white;
        transform-origin: center center;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        transition: all $fast-duration;
      }

      &__boundary {
        height: 100%;
        width: 100%;
        position: relative;
      }

      &__top {
        top: 0;
      }

      &__center {
        top: rem(9);
      }

      &__bottom {
        top: rem(19);
      }

      .header--mobile-menu & {
        span {
          width: rem(27);
        }

        &__top {
          top: rem(9);
          transform: rotate(45deg);
        }

        &__center {
          opacity: 0;
        }

        &__bottom {
          top: rem(9);
          transform: rotate(-45deg);
        }
      }
    }

    &-label {
      color: $white;
      text-transform: uppercase;
      font-size: rem(14);
      font-weight: $font-weight-bold;
    }
  }

  &__utility-navigation {
    display: flex;
    height: rem(64);
    align-items: center;
    justify-content: flex-end;

    .header--mobile-menu & {
      position: absolute;
      opacity: 0;
    }

    .menu {
      &__items {
        display: flex;
      }

      &__item {
        display: block;
        margin-right: rem(32);
      }

      &__link {
        display: none;
        color: $white;
        font-size: rem(14);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        transition-property: opacity;
        transition-duration: $fast-duration;

        .single & {
          color: $black;
        }

        @include bp(lg) {
          display: block;
        }

        &--button {
          @include box-button();

          @include bp(xs) {
            display: block;
          }
        }

        .header--show-menu & {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &__primary-navigation {
    transition: opacity $fast-duration ease;
    top: rem(71);
    left: 0;
    position: static;
    flex-shrink: 1;
    display: none;

    @include bp(lg) {
      display: block;
    }

    .menu {

      &__items {
        display: flex;
      }

      &__link--parent {
        color: $white;
        font-size: rem(18);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        transition-property: color, opacity;
        transition-duration: $fast-duration;

        html.single & {
          color: $black;
        }

        .header--desktop-menu & {
          color: $black;
          opacity: 0.25;
        }
      }

      &__item {
        padding-right: rem(22);

        &.current_page_item {
          .menu__link--parent {
            color: $green;
            position: relative;

            .header--desktop-menu & {
              color: $black;
            }

            &::after {
              width: calc(100% - 20px);
              height: 2px;
              background-color: $green;
              content: " ";
              display: block;
              position: absolute;
              bottom: -5px;
              left: 10px;
              transition: background-color $fast-duration;

              .header--desktop-menu & {
                background-color: $black;
              }
            }
          }
        }

        &.open .menu__link {
          opacity: 1;
        }
      }
    }
  }
}

//Hack for IE11
_:-ms-fullscreen, :root .header__bottom {
  top: 60px;
}
_:-ms-fullscreen, :root .menu--level-1 {
  top: -60px;
}